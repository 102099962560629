<template>
  <div class="v-services">
    <div class="v-services_articles">
      <h2 v-if="numArticle === '1'">Вернуть или обменять некачественный товар</h2>
      <h2 v-if="numArticle === '2'">Вернуть деньги за товар, который не подошел</h2>
      <h2 v-if="numArticle === '3'">Оспорить отказ в ремонте по гарантии</h2>
      <h2 v-if="numArticle === '4'">Получить компенсацию за оказание некачественных услуг</h2>
      <h2 v-if="numArticle === '5'">Вернуть деньги за товар из интернета</h2>
      <p>Узнайте шансы на решение своего спора по Закону РФ от 07.02.1992 N 2300-1 "О защите прав потребителей". Автоматизированная выборка основана на 4227 постановлениях и решениях судов всех инстанций за 2019 – 2021 гг.</p>
      <router-link tag="div" :class="{active: numArticle===articles[0]}" :to="{name: 'services', params: {id: articles[0]}}" class="articles-container">
        <div class="title">1</div>
        <div class="subtitle">Вернуть или обменять некачественный товар</div>
      </router-link>
      <router-link tag="div" :class="{active: numArticle===articles[1]}" :to="{name: 'services', params: {id: articles[1]}}" class="articles-container">
        <div class="title">2</div>
        <div class="subtitle">Вернуть деньги за товар, который не подошел</div>
      </router-link>
      <router-link tag="div" :class="{active: numArticle===articles[2]}" :to="{name: 'services', params: {id: articles[2]}}" class="articles-container">
        <div class="title">3</div>
        <div class="subtitle">Оспорить отказ в ремонте по гарантии</div>
      </router-link>
      <router-link tag="div" :class="{active: numArticle===articles[3]}" :to="{name: 'services', params: {id: articles[3]}}" class="articles-container">
        <div class="title">4</div>
        <div class="subtitle">Получить компенсацию за оказание некачественных услуг</div>
      </router-link>
      <router-link tag="div" :class="{active: numArticle===articles[4]}" :to="{name: 'services', params: {id: articles[4]}}" class="articles-container">
        <div class="title">5</div>
        <div class="subtitle">Вернуть деньги за товар из интернета</div>
      </router-link>
    </div>
    <div class="v-services_quest" v-if="numArticle === '1'">
      <div class="v-services_quest-container" v-for="quest in questions1" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div class="v-services_quest" v-if="numArticle === '2'">
      <div class="v-services_quest-container" v-for="quest in questions2" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div class="v-services_quest" v-if="numArticle === '3'">
      <div class="v-services_quest-container" v-for="quest in questions3" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div class="v-services_quest" v-if="numArticle === '4'">
      <div class="v-services_quest-container" v-for="quest in questions4" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div class="v-services_quest" v-if="numArticle === '5'">
      <div class="v-services_quest-container" v-for="quest in questions5" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div ref="form" class="v-services_form">
      <div class="title">
        ВВЕДИТЕ ДАННЫЕ
        <p>Получите результаты проверки информации по базе судебных актов</p>
      </div>
      <form>
        <div class="form-wrapper">
          <label>
            <span>Ваше имя</span>
            <input type="text" v-model="name" placeholder="Введите имя">
          </label>
          <label>
            <span>E-mail</span>
            <input type="email" v-model="email" placeholder="E-mail">
          </label>
          <label>
            <span>Телефон</span>
            <input type="text" class="new-input" required placeholder="Ваш телефон" data-inputmask="'mask': '+9(999)999-99-99'"  v-model="phone">
          </label>
        </div>
      </form>
      <button type="submit" @click="outMessUrist" onclick="ym(72714331,'reachGoal','order')">Узнать результаты</button>
      <p>Отправляя форму вы даёте согласие на обработку персональных данных</p>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '1'">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на автоматическом анализе 2330
        постановлений и решений по аналогичным делам. Искусственный интеллект
        определяет шансы на защиту прав потребителя
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по
        бесплатному телефону 8(800)201-32-36 предоставив бесплатную консультацию.
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
        <div class="answer-container">
          <div v-if="questions1[0].answer === questions1[0].valueAnswerTrue" class="title">При наличии чека.</div>
          <div v-else class="title">При отсутствии чека.</div>
          <div v-if="questions1[0].answer === questions1[0].valueAnswerTrue" class="subtitle">
            При наличии чека Вы имеете право вернуть или обменять некачественный товар. А в случае отказа в удовлетворении требований необходимо обратиться в надзорные органы и в суд.           </div>
          <div v-else class="subtitle">
            При отсутствии чека Вы можете доказать факт покупки опираясь на показания свидетелей и вернуть или обменять некачественный товар. В 69% случаев, когда были свидетели, потребители доказали факт покупки товара.          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions1[1].answer === questions1[1].valueAnswerTrue" class="title">Показания свидетелей.</div>
          <div v-else class="title">Если автомобиль стоял.</div>
          <div v-if="questions1[1].answer === questions1[1].valueAnswerTrue" class="subtitle">
            Показания свидетелей также как и чек являются доказательством покупки товара. 27% потребителей выиграли дело на основании показаний свидетелей          </div>
          <div v-else class="subtitle">
            Наличие свидетелей необязательно, факт покупки товара можно доказать с помощью других доказательств.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions1[2].answer === questions1[2].valueAnswerTrue" class="title">Если купили товар ненадлежащего качества.</div>
          <div v-else class="title">Если товар при продаже был надлежащего качества.</div>
          <div v-if="questions1[2].answer === questions1[2].valueAnswerTrue" class="subtitle">
            Вы имеете право на его ремонт за счет продавца, снижение цены, обмен на аналогичный или другой товар с дополнительной оплатой, а также на возврат денежных средств.          </div>
          <div v-else class="subtitle">
            Вы имеете право его вернуть только в течение 14 дней с момента покупки. Но есть в судебной практике и исключения.
          </div>
        </div>
      </div>
      <h5>Резюме</h5>
      <p>
        Нами разработано несколько способов для возврата прав за управление без номеров или со скрытыми номерами, в том числе и с получением штрафа. Как видно из предварительного автоматизированного анализа, у Вас есть все шансы на успех, но придется постараться этого успеха добиться. Есть положительные и отрицательные моменты, но после анализа протокола об административном правонарушении можно будет дать более точные шансы и определиться с окончательной линией защиты по Вашему делу.
      </p>
      <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
      <span>- бесплатные ходатайства</span><br>
      <span>- постановления и решения судов на которых основана эта информация</span><br>
      <span>- бесплатную консультацию и анализ материалов дела</span>
      <h3>
        С уважением,
        <br>
        Ваш Автоюрист
      </h3>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '2'">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на автоматическом
        анализе 2330 постановлений и решений по аналогичным делам. Искусственный
        интеллект определяет шансы
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по бесплатному
        телефону 8(800)201-32-36 предоставив бесплатную консультацию.
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
        <div class="answer-container">
          <div v-if="questions2[0].answer === questions2[0].valueAnswerTrue" class="title">При наличии чека.</div>
          <div v-else class="title">При отсутствии чека.</div>
          <div v-if="questions2[0].answer === questions2[0].valueAnswerTrue" class="subtitle">
            Вы имеете право вернуть товар в течение 14 дней с момента покупки. А в случае отказа в удовлетворении требований необходимо обратиться в надзорные органы и в суд.
          </div>
          <div v-else class="subtitle">
            При отсутствии чека Вы можете доказать факт покупки опираясь на показания свидетелей и вернуть товар в течение 14 дней. В 69% случаев, когда были свидетели, потребители доказали факт покупки товара.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions2[1].answer === questions2[1].valueAnswerTrue" class="title">Показания свидетелей.</div>
          <div v-else class="title">Наличие свидетелей.</div>
          <div v-if="questions2[1].answer === questions2[1].valueAnswerTrue" class="subtitle">
            Показания свидетелей также как и чек являются доказательством покупки товара. 27% потребителей выиграли дело на основании показаний свидетелей
          </div>
          <div v-else class="subtitle">
            Наличие свидетелей необязательно, факт покупки товара можно доказать с помощью других доказательств.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions2[2].answer === questions2[2].valueAnswerTrue" class="title">14 дней.</div>
          <div v-else class="title">14 дней.</div>
          <div v-if="questions2[2].answer === questions2[2].valueAnswerTrue" class="subtitle">
            Если с момента покупки товара надлежащего качества прошло 14 дней Вы не имеете право его вернуть
          </div>
          <div v-else class="subtitle">
            Если не прошло 14 дней с момента покупки товара, то Вы имеете право его вернуть
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions2[3].answer === questions2[3].valueAnswerTrue" class="title">Товарный вид.</div>
          <div v-else class="title">Товарный вид.</div>
          <div v-if="questions2[3].answer === questions2[3].valueAnswerTrue" class="subtitle">
            Если товар не был в употреблении, сохранены товарный вид, потребительские свойства,
            пломбы, фабричные ярлыки, а также имеется чек Вы можете его вернуть в течение 14 дней.
          </div>
          <div v-else class="subtitle">
            Если товар надлежащего качества был в употреблении, не сохранены товарный вид,
            потребительские свойства, пломбы или фабричные ярлыки Вы не имеете право его вернуть обратно.
          </div>
        </div>
      </div>
      <h5>Резюме</h5>
      <p>
        Нами разработано несколько способов для возврата прав за оставление места ДТП,
        в том числе и с получением ареста. Как видно из предварительного автоматизированного анализа,
        у Вас есть все шансы на успех, но придется постараться этого успеха добиться.
        Есть положительные и отрицательные моменты, но после анализа протокола об административном
        правонарушении можно будет дать более точные шансы и определиться с окончательной линией защиты
        по Вашему делу.
      </p>
      <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
      <span>- бесплатные ходатайства</span><br>
      <span>- постановления и решения судов на которых основана эта информация</span><br>
      <span>- бесплатную консультацию и анализ материалов дела</span>
      <h3>
        С уважением,
        <br>
        Ваш Автоюрист
      </h3>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '3'">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на автоматическом
        анализе 4227 постановлений и решений по аналогичным делам. Искусственный
        интеллект определяет шансы
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по бесплатному
        телефону 8(800)201-32-36 предоставив бесплатную консультацию.
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
        <div class="answer-container">
          <div v-if="questions3[0].answer === questions3[0].valueAnswerTrue" class="title">Чек.</div>
          <div v-else class="title">Чека нет.</div>
          <div v-if="questions3[0].answer === questions3[0].valueAnswerTrue" class="subtitle">
            Если купили товар ненадлежащего качества, при наличии чека, Вы имеете право на исправление его недостатков
            (ремонт). В 2020 году 86% потребителей, которые купили товар ненадлежащего качества и
            сохранили чек, обязали продавцов исправить недостатки товара.
          </div>
          <div v-else class="subtitle">
            При отсутствии чека Вы можете доказать факт покупки опираясь на показания свидетелей и
            вернуть товар в течение 14 дней. В 69% случаев, когда были свидетели, потребители доказали факт покупки товара.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions3[1].answer === questions3[1].valueAnswerTrue" class="title">Гарантийный срок.</div>
          <div v-else class="title">Гарантийный срок.</div>
          <div v-if="questions3[1].answer === questions3[1].valueAnswerTrue" class="subtitle">
            При истечении гарантийного срока в ряде случаев Вы имеете право на ремонт по гарантии.
          </div>
          <div v-else class="subtitle">
            Если Вы купили товар ненадлежащего качества и не истек гарантийный срок Вы имеете право на исправление недостатков (ремонт).
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions3[2].answer === questions3[2].valueAnswerTrue" class="title">Товар ненадлежащего качества.</div>
          <div v-else class="title">Товар надлежащего качества.</div>
          <div v-if="questions3[2].answer === questions3[2].valueAnswerTrue" class="subtitle">
            Если купили товар ненадлежащего качества Вы имеете право на его ремонт за счет продавца, снижение
            цены, обмен на аналогичный или другой товар с дополнительной оплатой, а также на возврат денежных средств.
          </div>
          <div v-else class="subtitle">
            Если товар при продаже был надлежащего качества Вы имеете право на ремонт по гарантии.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions3[3].answer === questions3[3].valueAnswerTrue" class="title">Ремонт по гарантии.</div>
          <div v-else class="title">Ремонт по гарантии.</div>
          <div v-if="questions3[3].answer === questions3[3].valueAnswerTrue" class="subtitle">
            Если обратились к продавцу с целью проведения ремонта по гарантии, но получили
            неудовлетворительный ответ необходимо обратиться в надзорные органы и в суд
          </div>
          <div v-else class="subtitle">
            Сначала необходимо написать претензию в досудебном порядке к продавцу, а потом уже
            в надзорные органы и в суд. В 2020 году 63% гражданских споров были решены в досудебном порядке.
          </div>
        </div>
      </div>
      <h5>Резюме</h5>
      <p>
        Есть 4 основных способа возврата прав за управление в состоянии опьянения.
        Как видно из предварительного автоматизированного анализа, у Вас есть все шансы на успех,
        но придется постараться этого успеха добиться. Есть положительные и отрицательные моменты,
        но после анализа протокола об административном правонарушении можно будет дать более точные
        шансы и определиться с окончательной линией защиты по Вашему делу и выбрать
        как раз один из 4-х основных вариантов.
      </p>
      <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
      <span>- бесплатные ходатайства</span><br>
      <span>- постановления и решения судов на которых основана эта информация</span><br>
      <span>- бесплатную консультацию и анализ материалов дела</span>
      <h3>
        С уважением,
        <br>
        Ваш Автоюрист
      </h3>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '4'">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на автоматическом
        анализе 2036 постановлений и решений по аналогичным делам. Искусственный
        интеллект определяет шансы
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по бесплатному
        телефону 8(800)201-32-36 предоставив бесплатную консультацию.
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
        <div class="answer-container">
          <div v-if="questions4[0].answer === questions4[0].valueAnswerTrue" class="title">Договор.</div>
          <div v-else class="title">Договор.</div>
          <div v-if="questions4[0].answer === questions4[0].valueAnswerTrue" class="subtitle">
            Договор об оказании услуг является доказательством того, что исполнитель обязан оказать услуги,
            указанные в договоре. В 85% подобных случаев, когда были составлены договоры, заказчики выиграли дело.
          </div>
          <div v-else class="subtitle">
            При отсутствии договора об оказании услуг факт взаимных обязательств можно доказать с
            помощью показаний свидетелей, сообщений в мессенджерах и т.д.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions4[1].answer === questions4[1].valueAnswerTrue" class="title"></div>
          <div v-else class="title"></div>
          <div v-if="questions4[1].answer === questions4[1].valueAnswerTrue" class="subtitle">
          </div>
          <div v-else class="subtitle">
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions4[2].answer === questions4[2].valueAnswerTrue" class="title"></div>
          <div v-else class="title"></div>
          <div v-if="questions4[2].answer === questions4[2].valueAnswerTrue" class="subtitle">
          </div>
          <div v-else class="subtitle">
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions4[3].answer === questions4[3].valueAnswerTrue" class="title">Свидетели.</div>
          <div v-else class="title">Свидетели.</div>
          <div v-if="questions4[3].answer === questions4[3].valueAnswerTrue" class="subtitle">
            Показания свидетелей также как и договор подтверждают факт взаимных обязательств и помогут выиграть дело.
          </div>
          <div v-else class="subtitle">
            Если есть договор об оказании услуг наличие свидетелей необязательно.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions4[4].answer === questions4[4].valueAnswerTrue" class="title">
            Ущерб.
          </div>
          <div v-else class="title">Ущерб.</div>
          <div v-if="questions4[4].answer === questions4[4].valueAnswerTrue" class="subtitle">
            Любой ущерб, в том числе и моральный, причиненный оказанием некачественных услуг подлежит возмещению
            виновной стороной в полном объеме. Согласно судебной практике, за 2020 год ущерб, причиненный
            ненадлежащим исполнением договора, был возмещен в 73% случаях.
          </div>
          <div v-else class="subtitle">
            При нарушении прав и свобод Вы имеете право на возмещение морального ущерба. Размер компенсации не зависит
            от объема нарушенных прав и свобод гражданина, он определяется судом по своему внутреннему убеждению.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions4[5].answer === questions4[5].valueAnswerTrue" class="title">
            Компенсация.
          </div>
          <div v-else class="title">Компенсация.</div>
          <div v-if="questions4[5].answer === questions4[5].valueAnswerTrue" class="subtitle">
            Если обращались к исполнителю для исправления недостатков или получения компенсации,
            но получили неудовлетворительный ответ Вам нужно обратиться в надзорные органы и в суд
          </div>
          <div v-else class="subtitle">
            В первую очередь нужно обратиться с претензией к исполнителю договора, а потом уже
            в государственные органы. В 2020 году 63% гражданских споров были решены в досудебном порядке.
          </div>
        </div>
      </div>
      <h5>Резюме</h5>
      <p>
        Нами используется как минимум 3 основных способа возврата прав за отказ
        от прохождения медицинского освидетельствования. Как видно из предварительного автоматизированного анализа,
        у Вас есть шансы на успех, но придется много поработать чтобы этого успеха добиться. Есть положительные и
        отрицательные моменты, есть сложные, а есть простые, но после анализа протокола об административном
        правонарушении можно будет дать более точные шансы в % и определиться с окончательной линией защиты по
        Вашему делу и выбрать как раз один из 3-х основных вариантов.
      </p>
      <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
      <span>- бесплатные ходатайства</span><br>
      <span>- постановления и решения судов на которых основана эта информация</span><br>
      <span>- бесплатную консультацию и анализ материалов дела</span>
      <h3>
        С уважением,
        <br>
        Ваш Автоюрист
      </h3>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === '5'">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на автоматическом
        анализе 1466 постановлений и решений по аналогичным делам. Искусственный
        интеллект определяет шансы
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по бесплатному
        телефону 8(800)201-32-36 предоставив бесплатную консультацию.
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
      </div>
      <h5>Резюме</h5>
      <p>
        Как видно из предварительного автоматизированного анализа, у Вас есть все шансы на успех, но придется постараться этого успеха добиться. Есть положительные и отрицательные моменты, но после анализа протокола об административном правонарушении можно будет дать более точные шансы и определиться с окончательной линией защиты по Вашему делу.
      </p>
      <h3>Звоните по бесплатному номеру 8(800)201-32-36 и юрист предоставит Вам:</h3>
      <span>- бесплатные ходатайства</span><br>
      <span>- постановления и решения судов на которых основана эта информация</span><br>
      <span>- бесплатную консультацию и анализ материалов дела</span>
      <h3>
        С уважением,
        <br>
        Ваш Автоюрист
      </h3>
    </div>
    <notice
      v-if="isPopup"
      @removePopup="removePopup"
      :isPopup="isPopup"
      :title="title"
      :subtitle="subtitle"
    />
  </div>
</template>

<script>
import axios from 'axios'
import Inputmask from 'inputmask'
import notice from './notification'
export default {
  name: 'v-services',
  components: { notice },
  data () {
    return {
      articles: [
        '1', '2', '3', '4', '5'
      ],
      questions1: [
        { value: 'Сохранился чек?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Есть ли свидетели?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Товар является некачественным?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' }
      ],
      questions2: [
        { value: 'У вас есть чек?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Есть свидетели?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Прошло 14 дней с момента покупки??', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Сохранен товарный вид?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' }
      ],
      questions3: [
        { value: 'Есть ли чек?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Истек гарантийный срок?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Товар является некачественным?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Обращались к продавцу для ремонта по гарантии?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' }
      ],
      questions4: [
        { value: 'Есть договор об оказании услуг?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Оплата производилась по реквизитам организации?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Оплата  производилась с карты на карту?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Есть свидетели, которые могут подтвердить факт оказания услуг?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Причинен ущерб имуществу или здоровью оказанием некачественных услуг?', answer: 5, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '5' },
        { value: 'Обращались за получением компенсации к исполнителю договора?', answer: 6, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '6' }
      ],
      questions5: [
        { value: 'Сохранился чек?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Товар надлежащего качества?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Продавец отвечает на Ваши сообщения?', answer: 3, valueAnswerTrue: 'Прошел на месте', valueAnswerFalse: 'Сразу в больницу', id: '3' },
        { value: 'Вам нужна бесплатная консультация юриста по защите прав потребителя? Вы узнаете как дополнительно получить 50% от суммы товара.', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' }
      ],
      result: [],
      name: null,
      email: null,
      phone: null,
      isPopup: false,
      title: null,
      subtitle: null
    }
  },
  computed: {
    numArticle () {
      return this.$route.params.id
    }
  },
  created () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  },
  methods: {
    removePopup () {
      this.isPopup = false
    },
    outMessUrist (e) {
      if (!this.name) {
        e.target.innerText = 'Вам необходимо указать как вас зовут'
        e.target.classList.add('error-send')
        this.isPopup = true
        this.title = 'Вам необходимо указать как вас зовут'
        this.subtitle = 'Пожалуйста, укажите Ваше имя. Это необходимо для более качественного предоставления услуг'
        setTimeout(() => {
          e.target.innerText = 'Узнать результаты'
          e.target.classList.remove('error-send')
        }, 800)
      } else if (!this.phone) {
        e.target.innerText = 'Вам необходимо указать email или телефон'
        e.target.classList.add('error-send')
        this.isPopup = true
        this.title = 'Вам необходимо указать Ваш номер телефона'
        this.subtitle = 'Пожалуйста, укажите Ваш телефон. Это необходимо для более качественного предоставления услуг. Ваши данные надежно защищены'
        setTimeout(() => {
          e.target.innerText = 'Узнать результаты'
          e.target.classList.remove('error-send')
        }, 800)
      } else {
        const vm = this
        vm.result.join()
        if (!this.name) e.target.innerText = 'Вам необходимо указать как вас зовут'
        else if (!this.phone && !this.email) e.target.innerText = 'Вам необходимо указать email или телефон'
        else {
          const reg = '^\\+\\d{1,3}\\s?\\(\\d{3}\\)\\s?\\d{3}(-\\d{2}){2}$' // для проверки правильности введенного номера
          if (this.phone.match(reg) !== null) {
            e.target.innerText = 'Ждите..'
            const formData = new FormData()
            formData.append('crm', '16')
            formData.append('pipe', '34')
            formData.append('contact[name]', this.name)
            formData.append('contact[466]', this.phone)
            formData.append('contact[467]', this.email)

            // для chance.avtourist.info
            // formData.append('lead[214]', 'ЕБСА')
            // formData.append('note', 'Заявка с chance.avtourist.info по статье ' + this.numArticle + '<br>' + this.result.join())

            // для mychance.avtourist.info
            formData.append('lead[541]', 'зпп.мфюц.рф')
            formData.append('note', 'Заявка с зпп.мфюц.рф по статье ' + (
              this.numArticle === '1' ? 'Вернуть или обменять некачественный товар' : this.numArticle === '2' ? 'Вернуть деньги за товар, который не подошел' : this.numArticle === '3' ? 'Оспорить отказ в ремонте по гарантии' : this.numArticle === '4' ? 'Получить компенсацию за оказание некачественных услуг' : this.numArticle === '5' ? 'Вернуть деньги за товар из интернета' : 'ошибка.'
            ) + '<br>' + this.result.join())

            axios.post('https://bez.v-avtoservice.com/api/import-lead',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
            ).then(res => {
              e.target.innerText = 'Заявка отправлена!'
              document.getElementById('answer').style.display = 'block'
              document.getElementById('answer').scrollIntoView()
              document.getElementById('modalSubscribe').style.display = 'block'
              document.getElementById('modalSuccessSend').style.display = 'block'
              this.$refs.form.remove()
            })
          } else {
            this.isPopup = true
            this.title = 'Номер не корректный!'
            this.subtitle = 'Пожалуйста, укажите правильно номер вашего телефона. Ваши данные надежно защищены.'
          }
        }
      }
    }
  },
  mounted () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  }
}
</script>

<style lang="scss" scoped>
  .v-services_articles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    h2 {
      flex-basis: 100%;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
    p {
      flex-basis: 100%;
      color: rgba(0, 0, 0, 0.4);
    }
    .articles-container {
      cursor: pointer;
      width: 198px;
      height: 160px;
      margin: 10px;
      padding: 10px 0 0 10px;
      background: rgba(32, 209, 82, 0.08);
      border: 1px solid rgba(32, 209, 82, 0.16);
      box-sizing: border-box;
      border-radius: 10px;
      position: relative;
      &::before {
        content: '';
        width: 25px;
        height: 15px;
        bottom: 10px;
        right: 10px;
        position: absolute;
        background: url("../assets/img/icons/arrow.svg") center no-repeat;
      }
      .title {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #20D152;
      }
      &.active .title {
        color: #FFFFFF;
      }
      &.active {
        background: #20D152;
        color: #FFFFFF;
      }
    }
  }
  .v-services_quest {
    margin-bottom: 45px;
    .v-services_quest-container {
      display: flex;
      align-items: center;
      padding: 2px 2px 2px 15px;
      margin: 10px 10px;
      height: 40px;
      border-radius: 3px;
      border: 1px solid #E0E0E0;
      span {
        max-width: 50%;
      }
      .answer-wrapper {
        margin-left: auto;
        label {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          min-width: 120px;
          width: 200px;
          height: 38px;
          background: rgba(32, 209, 82, 0.08);
          margin: 0 2px;
          border-radius: 2px;
          border: 1px solid #E0E0E0;
          transition-duration: .3s;
          cursor: pointer;
          &.active {
            color: #FFFFFF;
            background: #20D152;
          }
          &:active {
            transform: scale(0.8);
          }
        }
      }
    }
  }
  .v-services_form {
    .title {
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .form-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 24px;
        label {
          margin: 10px 5px;
          position: relative;
          max-width: 30%;
          min-width: 200px;
          width: 30%;
          &:focus {
            color: #0F8831;
          }
          input {
            width: 100%;
            border: 1px solid #20D152;
            box-sizing: border-box;
            border-radius: 6px;
            height: 48px;
            padding: 20px 14px;
            &:focus {
              color: #0F8831;
            }
          }
          span {
            position: absolute;
            top: -15px;
            left: 5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
    button {
      display: block;
      width: 220px;
      height: 48px;
      background: #20D152;
      margin: 0 auto;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 16px;
    }
    p {
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.36);
    }
  }
  .v-services_answer {
    margin-bottom: 50px;
    .answer-wrapper {
      padding: 15px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      border-radius: 16px;
      .answer-container {
        margin: 15px 0;
        background: #FFFFFF;
        padding: 15px;
        box-shadow: 0 0 10px 2px rgba(221, 221, 221, 1);
        box-sizing: border-box;
        border-radius: 8px;
        .title {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          text-transform: uppercase;
        }
      }
    }
  }
  @media (max-width: 1110px) {
    .v-services_quest {
      .v-services_quest-container {
        display: flex;
        flex-wrap: wrap;
        height: initial;
        padding: 10px;
        span {
          margin-bottom: 10px;
          max-width: initial;
        }
        .answer-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          label {
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .v-services_articles {
      margin-bottom: 30px;
      h2 {
        font-size: 22px;
        line-height: 26px;
      }
      .articles-container {
        width: calc(100% - 30px);
        height: 110px;
        margin: 5px;
        .title {
          margin-bottom: 7px;
        }
      }
    }
    .v-services_form {
      .title {
      }
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .form-wrapper {
          label {
            margin: 10px 5px;
            position: relative;
            max-width: initial;
            min-width: 200px;
            width: 100%;
          }
        }
      }
      button {
        display: block;
        width: 220px;
        height: 48px;
        background: #20D152;
        margin: 0 auto;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 16px;
      }
      p {
        text-align: center;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.36);
      }
    }
  }
</style>
